.bannerTop img{
    width: 100%;
}

.bannerTop{
    max-height: 450px;
    overflow: hidden;
}

.Container {
    padding: 150px;
    padding-top: 180px;
}

.Container h2{
    font-family: 'Shrikhand', cursive;
    font-size: 3em;
    text-shadow: 3px 3px rgb(8, 8, 8);
    color: white;  
    padding-bottom: 30px;  
}

.Container p{
    font-size: 1.25em;
}
/* 
.row img{
    width: 90%;
    height: 100%;
    object-fit: cover;
} */


.img2 img{
    width: 90%;
    height: 100%;
    object-fit: cover;
    padding-top: 130px;
}

.img1 img{
    width: 90%;
    height: 100%;
    object-fit: cover;
    padding-bottom: 130px;
}

.Container-White{
    padding-top: 150px;
    padding-bottom: 150px;
}

.containerText{
    padding: 100px;
    background: white;
    /* box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24); */
    display: 'flex';
    margin-left: 5%;
    margin-right: 5%;
    z-index: 1000;
}


.Container-White .containerText h3{
    font-size: 4em;
} 
.Container-White .containerText a{
    text-decoration-thickness: 1px;
    text-underline-offset: 0.2em;
    text-decoration-color: gray;
    color: gray;
    display: inline-block;
}

.Container-White .containerText p{
    font-size: 1.25em;
} 

.img3 img{
    width: 90%;
    height: 100%;
    object-fit: cover;
}


@media only screen and (max-width: 600px) {
    .Container {
        text-align:center;
        padding: 10px;
        padding-bottom: 50px;
    }
    .Container-White{
        text-align:center;
        padding: 10px;
        padding-bottom: 50px;
    }
    .img1 img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .img2 img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .img3 img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .Container-White .containerText h3{
        font-size: 2em;
    } 
    
    .Container-White .containerText p{
        font-size: 2em;
    } 
    
  }
