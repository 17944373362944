*{
    padding:0;
    margin:0;
    box-sizing: border-box;
}

.zoom{
    width: 100%;
    height: 1400px;
    position: relative;
    z-index: 1000;
    background: url('../images/wavyBackground.png');
    background-repeat: no-repeat;
    overflow: hidden;
    background-size: cover;
}

.zoom::before{
    content: '';
    position: absolute;
    height: 150px;
    width: 100%;
    bottom: 0%
}
 

#avocadoLeft{
    padding-top: 200px;
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    z-index: 100;
}

#avocadoRight{
    padding-top: 200px;
    position: absolute;
    z-index: 100;
}

#avocadoLoveLogo {
    /* position: absolute; */
    width: 100%;
    justify-content: center;
    /* transform: translateY(10%); */
    transform: translateY(-10%); 
}

.content-links{
    width:100%;
    margin: 0 auto;
    text-align: center;
    padding-top: 150px;
    padding-bottom: 150px;
    color: white;
    font-family: 'Shrikhand', cursive;
    /* background-color: white; */
    text-shadow: 3px 3px black;
}

.content-links h2 {
    font-size: 4em;
}
.content-links h3 {
    font-size: 3em;
}
.content-links img{
    border-radius: 50%;
    border: 5px solid #fff;
}

.content-links a{
    font-size: 3em;
    text-decoration:none;
    color: white;
}

.content-links a:hover{
    color: lightgray;
}


.imgLinks {
    padding-top: 100px;
    padding-left: 20px;
    padding-right: 20px;
}

.imgLinks img{
    width: 70%;
}

.avocadoNutrition{
    padding-top: 150px;
    display: flex;
    color: black;
    background-color: white;
    padding-bottom: 150px;
    padding-left: 20px;
    padding-right: 20px;
}

.avocadoNutrition h2{
    text-align: center;
    font-family: 'Shrikhand', cursive;
    font-size: 70px;
    text-shadow: 3px 3px rgb(8, 8, 8);
    color: #40bb5a;
}

.avocadoNutrition img{
    width: 100%;
    justify-content: center;
    /* object-fit: contain; */
}

.avocadoNutrition ul li{
    /* font-weight:  bold; */
    font-size: 35px;
    padding: 10px;
    padding-left: 20px;
}

.healthyHappy{
    width:100%;
    margin: 0 auto;
    text-align: center;
    padding-top: 150px;
    padding-bottom: 150px;
    color: white;
    font-family: 'Shrikhand', cursive;
    /* background-color: white; */
    text-shadow: 3px 3px black;
}
.healthyHappy img{
    width: 100%;
}

.healthyHappy h2{
    font-size: 4em;
}
