.content{
    padding-top: 150px;
    text-align: center;
    padding-left: 200px;
    padding-right: 200px;
    font-family: 'Shrikhand', cursive;
    color: white;
    text-shadow: 3px 3px black;
}

@media only screen and (max-width: 950px) {
    .content{
        padding-left: 10px; 
        padding-right: 10px;
  }
}

button{
    background: none;
    border: 2px solid #fff;
    border-radius: 4px;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    font-size: 1em;
    height: 100%;
    width: 100%;
    padding: 20px;
}

button:hover{
    background: #7e797b;
    color: #fff;
}

.card-grid{
    margin-top: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
}

.primaryModal button{
    color: black;
}

.buttonArea{
    padding-bottom: 100px;
    padding-top: 100px;
    width: 100%;
}