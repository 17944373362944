.Content{
    padding: 50px;
    padding-top: 120px;
    text-align: center;
}

.Content img{
    width: 100%;
}

.Content p {
    color:white;
    font-size: 45px;
}

.Content h2{
    padding-bottom: 40px;
    color: white;
    font-family: 'Shrikhand', cursive;
    /* background-color: white; */
    text-shadow: 3px 3px black;
    text-align: center;
    font-size: 80px;
}

.Content h3{
    color: black;
    text-align: center;
    font-size: 35px;
    padding-bottom: 40px;
}

.ingredientsSection{
    padding: 100px;
    padding-bottom: 0px;
    /* padding-top: 100px; */
}

.ingredientsSection li{
    text-align: left;
    font-size: 30px;
    color: white;
    list-style-type: none;
}

.instructionsSection{
    padding: 100px;
}

.instructionsSection ol{
    text-align: left;
    font-size: 30px;
    color: white;
}

@media only screen and (max-width: 600px) {
    .Content{
        padding: 10px;
    }
    .Content h2{
        padding-bottom: 40px;
        font-size: 30px;
    }
    .Content p {
        font-size: 30px;
    }

    .Content img{
        padding-bottom: 30px;
    }
    
    .Content h3{
        font-size: 15px;
    }
    
    .ingredientsSection{
        padding: 10px;
        padding-top: 50px;
        padding-bottom:40px;
    }
    
    .ingredientsSection li{
        text-align: left;
        font-size: 20px;
    }
    
    .instructionsSection{
        padding: 10px;
        padding-top: 50px;
    }
    
    .instructionsSection ol{
        font-size: 20px;
    }
}
