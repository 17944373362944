
.RecipesPage h2{
    padding-top: 80px;
    padding-bottom: 40px;
    color: white;
    font-family: 'Shrikhand', cursive;
    /* background-color: white; */
    text-shadow: 3px 3px black;
    text-align: center;
    font-size: 80px;
}

.RecipesPage h3{
    color: black;
    text-align: center;
    font-size: 35px;
    padding-bottom: 40px;
}



.recipe-list{
    display: grid;
    grid-gap: 40px;
    max-width: 1200px;
    margin: 40px auto;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 80px;
    grid-template-columns: repeat(2, minmax(300px, 1fr));
}
@media (max-width: 600px){
  .recipe-list{
    grid-template-columns: 1fr;
  }
}

.recipe-list .card{
    background: #fff;
    color: #333;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 3px 3px 3px rgba(0,0,0,0.05);
    transition: all .3s ease;
    position: relative;
    text-align: center;
}

.recipe-list .card:hover {
    transform: rotate(3deg)
}

.recipe-list .card h3{
    color: black;
    margin-bottom: 1px;
}

.recipe-list .card p{
    color: gray;
    font-size: 1.2em;
}


.recipe-list .card .linkBttn{
    color: white;
    text-decoration: none;
    display: block;
    background: green;
    font-size: 0.9em;
    text-align: center;
    width:120px;
    padding: 8px;
    border-radius: 4px;
    margin: 20px auto 0;
}
