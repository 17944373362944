@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Shrikhand&display=swap');

.body{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #40bb5a;
}

:root{
  --mainGreen:  #40bb5a;
  --mainDarkGreen:  #2b6337;
  --mainWhite: #FFF;
  --mainGrey: #bbc2ca;


}