.section{
    padding-top: 80px;
    padding-bottom:80px;
}


.section h2{
    color: white;
    font-family: 'Shrikhand', cursive;
    /* background-color: white; */
    text-shadow: 3px 3px black;
    text-align: center;
    font-size: 80px;
}

.heading{
    text-align: center;
    padding-top: 20px;   
    padding-bottom: 55px;
}

.heading .h3{
    font-size: 40px;
}

.contact{
    background: white;
    padding: 10px;
    /* box-shadow: 5px 10px 5px gray; */
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
    display: 'flex';
    align-items: 'center';
    justify-content: 'center';
    margin-left: 5%;
    margin-right: 5%;
    z-index: 1000;
}

.ContactInfo{
    /* position: fixed;
    display: flex;
    justify-content: center; */
    /* align-items: center; */
    /* flex-direction: column;
    top: 50%;
    transform: translateY(-50%);  */

}

.ContactInfo h2{
    font-size: 36px;
    font-weight: 500;
    color: black;
}
.ContactInfo p{
    font-weight: 300;
    color: black;
}
.ContactInfo a{
    font-weight: 300;
    color: black;
    text-decoration: none;
}
.ContactInfo a:hover {
    color: var(--mainGrey);
}

.ContactInfo .box-info{
    position: relative;
    padding: 20px 0;
    display: flex;
    padding-right: 20px;
}

.ContactInfo .box-info .icon{
    min-width: 60px;
    height: 60px;
    background: #f2f2f2;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 22px;
}

.ContactInfo .box-info .text{
    display: flex;
    margin-left: 20px;
    font-size: 16px;
    color: black;
    flex-direction: column;
    font-weight: 300;
}

  .register-form {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 10px;
  }
  
  .success-message {
    font-family: "Roboto", sans-serif;
    background-color: #3f89f8;
    padding: 15px;
    color: white;
  }
  
  .form-field {
    margin: 10px 0 10px 0;
    padding: 15px;
    font-size: 16px;
    border: 0;
    font-family: "Roboto", sans-serif;
  }
  
  span {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    color: red;
    margin-bottom: 15px;
  }
  
  input, textarea {
    background: #f2f2f2;
  }


  
  .error {
    border-style: solid;
    border: 2px solid #ffa4a4;
  }
  
  button {
    background: #4caf50;
    color: white;
    cursor: pointer;
  }
  
  button:disabled {
    cursor: default;
  }


  .section .map iframe{
    padding-top: 50px;
    display: block;
    margin: auto;
    width: 70%;
    height: 500px;
  }

